import { createRouter, createWebHistory } from "vue-router";
import { nextTick } from 'vue';

import i18n from "../i18n.js";
import HomeView from "../views/HomeView.vue";
import GlobalOffice from "../views/GlobalOffice.vue";
import License from "../views/License.vue";
import GroupIntroduction from "../views/GroupIntroduction.vue";
import BusinessOverview from "../views/BusinessOverview.vue";
import Brokerage from "../views/Brokerage.vue";
import WealthManagement from "../views/WealthManagement.vue";
import PaymentExchange from "../views/PaymentExchange.vue";
import Fintech from "../views/Fintech.vue";
import FinancialEducation from "../views/FinancialEducation.vue";
import Healthcare from "../views/Healthcare.vue";
import ContactUs from "../views/ContactUs.vue";
import DisclosureEmail from "../views/DisclosureEmail.vue";
import Milestone from "../views/Milestone.vue";
import DefaultLayout from "../layout/DefaultLayout.vue";
import ManUtd from "@/views/ManUtd.vue";
import Property from "@/views/Property.vue";
import Consult from "@/views/Consult.vue";
import Cloud from "@/views/Cloud.vue";
import DigitalMarketing from "@/views/DigitalMarketing.vue";
import AUFC from "@/views/AUFC.vue";

const routes = [
  {
    path: "/",
    redirect: "/en",
  },
  {
    path: "/:lang/",
    name: "root",
    component: DefaultLayout,
    children: [
      {
        path: "",
        name: "home",
        component: HomeView,
      },
      {
        path: "globalOffice",
        name: "globalOffice",
        component: GlobalOffice,
      },
      {
        path: "license",
        name: "license",
        component: License,
      },
      {
        path: "groupIntroduction",
        name: "groupIntroduction",
        component: GroupIntroduction,
      },
      {
        path: "milestone",
        name: "milestone",
        component: Milestone,
      },
      {
        path: "businessoverview",
        name: "businessoverview",
        component: BusinessOverview,
      },
      {
        path: "brokerage",
        name: "brokerage",
        component: Brokerage,
      },
      {
        path: "wealthmanagement",
        name: "wealthmanagement",
        component: WealthManagement,
      },
      {
        path: "paymentexchange",
        name: "paymentexchange",
        component: PaymentExchange,
      },
      {
        path: "fintech",
        name: "fintech",
        component: Fintech,
      },
      {
        path: "financialeducation",
        name: "financialeducation",
        component: FinancialEducation,
      },
      {
        path: "healthcare",
        name: "healthcare",
        component: Healthcare,
      },
      {
        path: "contactus",
        name: "contactus",
        component: ContactUs,
      },
      {
        path: "partnership/manutd/",
        name: "manutd",
        component: ManUtd,
      },
      {
        path: "partnership/aufc/",
        name: "aufc",
        component: AUFC,
      },
      {
        path: "property",
        name: "property",
        component: Property,
      },
      {
        path: "consult",
        name: "consult",
        component: Consult,
      },
      {
        path: "cloud",
        name: "cloud",
        component: Cloud,
      },
      {
        path: "digital-marketing",
        name: "digitalmarketing",
        component: DigitalMarketing,
      },
    ],
  },
  {
    path: "/disclosure/email",
    name: "disclosureEmail",
    component: DisclosureEmail,
  },
];


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0, left: 0 }
  }
});

router.beforeEach((to, from, next) => {
  i18n.global.locale.value = to.path.split("/")[1];

  document.documentElement.setAttribute("lang", i18n.global.locale.value);

  if (to?.meta?.metaTags != undefined) {
    for (const [key, value] of Object.entries(to.meta.metaTags)) {
      const tag = document.createElement("meta");
      Object.keys(value).forEach((key) => {
        tag.setAttribute(key, value[key]);
      });
      document.head.appendChild(tag);
    }
  }

  router.afterEach((to, from) => {
    const DEFAULT_TITLE = 'DEFAULT_TITLE';

    nextTick(() => {
      let meta = document.getElementsByTagName("meta");
      if (to.path.includes("/partnership/aufc")) {
        document.title = to.meta.title || i18n.global.t('aufc.seo.title')
        for (let i=0; i<meta.length; i++) {
            meta[i].description = i18n.global.t('aufc.seo.desc')
        }
      }
    });
  });

  return next();
});

export default router;
