<template>
    <Teleport to="head">
      <title>{{$t("seo_title")}} | {{$t("seo_title2")}}</title>
      <meta name="description" :content="$t('seo_desc')"/>
      <meta name="keyword" :content="$t('seo_keyword')"/>
    </Teleport>
    
    <HomeBanner></HomeBanner>
    <TheStatistics></TheStatistics>
    <TheFuture></TheFuture>
    <TheLicense></TheLicense>
    <GlobalPresence></GlobalPresence>
    <GlobalPartnership></GlobalPartnership>
    <EsgBlock></EsgBlock>
    <LatestNews />
    <FutureOpportunity></FutureOpportunity>
    <Modals />
    
</template>

<script>
// @ is an alias to /src
import HomeBanner from '../components/LandingPage/HomeBanner.vue'
import TheStatistics from '../components/LandingPage/TheStatistics.vue'
import TheFuture from '../components/LandingPage/TheFuture.vue'
import TheLicense from '../components/LandingPage/TheLicense.vue'
import GlobalPresence from '../components/LandingPage/GlobalPresence.vue'
import GlobalPartnership from '../components/LandingPage/GlobalPartnership.vue'
import FutureOpportunity from '../components/LandingPage/FutureOpportunity.vue'
import LatestNews from '../components/LandingPage/LatestNews.vue'
import EsgBlock from '../components/LandingPage/ESGBlock.vue'
import Modals from '@/components/Modals/ModalMain.vue'

export default {
  name: 'HomeView',
  components: {
    HomeBanner,
    TheStatistics,
    TheFuture,
    TheLicense,
    GlobalPresence,
    GlobalPartnership,
    FutureOpportunity,
    LatestNews,
    EsgBlock,
    Modals
  },
  methods: {
  },
  mounted() {
  }
}
</script>

<style scoped lang="less">
.home-view {
  margin: 0;
  padding: 0;
}
</style>
