<template>
  <div :class="['three-image-row three-blog-row',  overflow ? 'three-blog-row-margin-bottom' : '' ]" :style="{ marginRight: overflow ? 'unset' : 'auto', marginLeft: overflow ? 'unset' : 'auto' }">

    <div ref="scrollRef" id="article-scrollRef">
        <div :class="['image-row-content blog-content', overflow ? 'first-blog' : '']" @click="openModalArticle('5', '11th November 2023')">
            <img :src=ImageMilestone5 class="center-image" />
            <div class="first-image-content">
                <img :src="RedArrowRight" alt="" class="small-arrow-icon" />
                <div>
                    <h3 class="title">{{ t('mu.milestone_title_5') }} <span class="date">11th November 2023</span></h3>
                    
                    <p class="description" v-html="(t('mu.milestone_description_5')).replace(/(\r\n|\n|\r)/gm, '<br/>')"></p>
                </div>
            </div>
        </div>
        <div class="image-row-content blog-content" @click="openModalArticle('4')">
            <img :src=ImageMilestone4 class="center-image" />
            <div>
                <img :src="RedArrowRight" alt="" class="fade-arrow-icon" style="opacity: 0.5;" />
                <img :src="RedArrowRight" alt="" class="small-arrow-icon" />
                <div>
                    <h3 class="title">{{ t('mu.milestone_title_4') }}</h3>
                    <p class="description" v-html="(t('mu.milestone_description_4')).replace(/(\r\n|\n|\r)/gm, '<br/>')"></p>
                </div>
            </div>
        </div>
        <div class="image-row-content blog-content" @click="openModalArticle('3', '13th May 2023')">
            <img :src=ImageMilestone3 class="center-image" />
            <div>
                <img :src="RedArrowRight" alt="" class="fade-arrow-icon" style="opacity: 0.5;" />
                <img :src="RedArrowRight" alt="" class="small-arrow-icon" />
                <div>
                    <h3 class="title">{{ t('mu.milestone_title_3') }} <span class="date">13th May 2023</span></h3>
                    <p class="description" v-html="(t('mu.milestone_description_3')).replace(/(\r\n|\n|\r)/gm, '<br/>')"></p>
                </div>
            </div>
        </div>
        <div class="image-row-content blog-content" @click="openModalArticle('2', '9th February 2023')">
            <img :src=ImageMilestone2 class="center-image" />
            <div>
                <img :src="RedArrowRight" alt="" class="fade-arrow-icon" style="opacity: 0.5;" />
                <img :src="RedArrowRight" alt="" class="small-arrow-icon" />
                <div>
                    <h3 class="title">{{ t('mu.milestone_title_2') }} <span class="date">9th February 2023</span></h3>
                    <p class="description" v-html="(t('mu.milestone_description_2')).replace(/(\r\n|\n|\r)/gm, '<br/>')"></p>
                </div>
            </div>
        </div>
        <div class="image-row-content blog-content" @click="openModalArticle('1', '4th January 2023')">
            <img :src=ImageMilestone1 class="center-image" />
            <div>
                <img :src="RedArrowRight" alt="" class="fade-arrow-icon" style="opacity: 0.5;" />
                <img :src="RedArrowRight" alt="" class="small-arrow-icon" />
                <div>
                    <h3 class="title">{{ t('mu.milestone_title_1') }} <span class="date">4th January 2023</span></h3>
                    <p class="description" v-html="(t('mu.milestone_description_1')).replace(/(\r\n|\n|\r)/gm, '<br/>')"></p>
                </div>
            </div>
        </div>
    </div>
    
    <template v-if="overflow">
      <button class="next-button" @click="handleNextOnClick" :disabled="scrollPosition.right">
        <img :src="scrollPosition.left ? buttonRightOn : buttonRightOff" />
      </button>
      
      <button class="previous-button" @click="handlePreviousOnClick" :disabled="scrollPosition.left">
        <img :src="scrollPosition.left ? buttonLeftOff : buttonLeftOn" />
      </button>
    </template>
  </div>
</template>

<script setup>
  import { ref, onMounted, onUnmounted, reactive, watch, nextTick } from 'vue';
  import { useI18n } from 'vue-i18n';
  import ImageMilestone1 from "@/assets/MuLanding/Image-Milestones1.png";
  import ImageMilestone2 from "@/assets/MuLanding/Image-Milestones2.png";
  import ImageMilestone3 from "@/assets/MuLanding/Image-Milestones3.png";
  import ImageMilestone4 from "@/assets/MuLanding/Image-Milestones4.png";
  import ImageMilestone5 from "@/assets/MuLanding/Image-Milestones5.png";
  import RedArrowRight from "@/assets/MuLanding/icon-arrow-right-red.png";
  import buttonLeftOff from "@/assets/MuLanding/Button_arrow_leftOff.png";
  import buttonLeftOn from "@/assets/MuLanding/Button_arrow_leftOn.png";
  import buttonRightOff from "@/assets/MuLanding/Button_arrow_rightOff.png";
  import buttonRightOn from "@/assets/MuLanding/Button_arrow_rihgtOn.png";
  import { useCommonStore } from '@/stores/common'

  const commonStore = useCommonStore()
  
  const { t } = useI18n();

  const scrollRef = ref(null);
  const blogWidth = 452 + 12;
  const scrollPosition = reactive({ left: true, right: false });
  const overflow = ref(true);

  onMounted(() => {

    if (scrollRef.value) {
      scrollRef.value.addEventListener("scroll", handleScrollEvent);
    }
    window.addEventListener("resize", handleCheckOverflow);
  });
  
  onUnmounted(() => {
    handleCheckOverflow();
    if (scrollRef.value) {
      scrollRef.value.removeEventListener("scroll", handleScrollEvent);
    }
    window.removeEventListener("resize", handleCheckOverflow);
  });

  const handleScrollEvent = () => {
    if (scrollRef.value.scrollLeft === 0) scrollPosition.left = true;
    else if (scrollRef.value.scrollLeft + scrollRef.value.offsetWidth >= scrollRef.value?.scrollWidth)
      scrollPosition.right = true;
    else {
      scrollPosition.left = false;
      scrollPosition.right = false;
    }
  };
  
  const handleCheckOverflow = () => {
    overflow.value = scrollRef.value?.scrollWidth > scrollRef.value?.clientWidth;
  };
  
  const handleNextOnClick = () => {
    if (scrollRef.value) {
      scrollRef.value.scroll({
        left: scrollRef.value.scrollWidth,
        behavior: "smooth",
      });
    }
  };
  
  const handlePreviousOnClick = () => {
    if (scrollRef.value) {
      scrollRef.value.scroll({
        left: -scrollRef.value.scrollWidth,
        behavior: "smooth",
      });
    }
  };

  const openModalArticle = (number, date) => {
    if(window.innerWidth < 836) {
      commonStore.setArticle(number, date)
      commonStore.openModal('MUArticles')
    }
  }

</script>
  
