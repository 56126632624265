<template>
  <div class="home-view">
    <router-view/>
  </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";

export default {
  name: 'App',
  mounted() {
    AOS.init();
  }
}
</script>

