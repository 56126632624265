<template>
    <div class="three-image-banner-main-container">
        <div v-for="n in 3" :class="[(n == 2) ? 'red-bg' : '']">
            <div >
                <div v-if="n == 2" class="isDesktop col-span-1"></div>
                <div v-if="n != 2" :class="['three-image-banner-image-content isDesktop', (n == 2) ? 'col-span-4' : 'col-span-7']">
                    <img :src="ImageValue[n]" alt="" />
                </div>
                <div class="three-image-banner-content col-span-4" data-aos="fade-right">
                    <div>
                        <img v-if="n != 2" src="../../assets/MuLanding/icon-arrow-right-red.png" alt="red arrow" class="arrow-icon" />
                        <img v-else src="../../assets/MuLanding/icon-arrow-right-white.webp" alt="white arrow" class="arrow-icon" />
                        <div>
                            <div class="title">
                                {{ (n == 1) ? `${t('mu.fisrt_image_title_1')}` : `` }}
                                {{ (n == 2) ? `${t('mu.second_image_title_1')}` : `` }}
                                {{ (n == 3) ? `${t('mu.third_image_title_1')}` : `` }}
                                <span style="fontFamily: Oswald;">
                                    {{ (n == 1) ? `${t('mu.fisrt_image_title_2')}` : `` }}
                                    {{ (n == 2) ? `${t('mu.second_image_title_2')}` : `` }}
                                    {{ (n == 3) ? `${t('mu.third_image_title_2')}` : `` }}
                                </span>
                            </div>
                            <div class="description">
                                {{ (n == 1) ? `${t('mu.fisrt_image_description')}` : `` }}
                                {{ (n == 2) ? `${t('mu.second_image_description')}` : `` }}
                                {{ (n == 3) ? `${t('mu.third_image_description')}` : `` }}
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="n == 2" class="three-image-banner-image-content-float col-span-7">
                    <img :src="ImageValue[n]" alt="" />
                </div>
                <div v-else class="isMobile three-image-banner-image-content-float col-span-7">
                    <img :src="ImageValue[n]" alt="" />
                </div>
                <div class="isMobile col-span-1"></div>
            </div>
        </div>
    </div>
  </template>
  
<script setup>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
  
  const { t } = useI18n();
  const isMobile = ref(false);
  const red = ref(false);

  const ImageValue = [
    null, 
    require('@/assets/MuLanding/Image-Values1.png'), 
    require('@/assets/MuLanding/Image-Values2-new.png'), 
    require('@/assets/MuLanding/Image-Values3.png')  
  ];
  
</script>

<style>
.col-span-7 {
    grid-column: span 7 / span 7;
}
.col-span-4 {
    grid-column: span 4 / span 4;
}
</style>