<template>
    <section id="health-care">
        <div class="dh" @click="redirectPage">
            <div class="wrapper">
                <div class="wrapper-content">
                    <img class="dc-logo logo"
                        src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/sub-brand/DH-Colour-Dark-BG.svg"
                        alt="health care">
                    <p class="desc" v-t="'better-future.health-care-desc'"></p>
                    <div class="desc-link">
                        <a href="https://health.doo.com/" v-t="'banner.learn-more'"></a>
                        <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                            <path fill="#ffffff"
                                d="m3.55 12l7.35 7.35q.375.375.363.875t-.388.875q-.375.375-.875.375t-.875-.375l-7.7-7.675q-.3-.3-.45-.675T.825 12q0-.375.15-.75t.45-.675l7.7-7.7q.375-.375.888-.363t.887.388q.375.375.375.875t-.375.875L3.55 12Z" />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

export default {
    name: 'HealthCare',
    data() {
        return {
            isMobile: false
        }
    },
    methods: {
        redirectPage() {
            if (this.isMobile) {
                window.open('https://health.doo.com/', '_blank');
            }
        },
        checkMobile() {
            if (window.innerWidth <= 835) {
                this.isMobile = true
            } else {
                this.isMobile = false
            }
        }
    },
    mounted() {
        this.checkMobile()
        window.addEventListener("resize", this.checkMobile);
    }
}

</script>


<style lang="less" scoped>
#health-care {
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width:835px) {
        flex-direction: column;
        gap: 16px;
    }

    .dh {

        @media only screen and (max-width:835px) {
            background-image: url('https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/health-care/Brand-DH-mobile.png');
        }

        background-image: url('https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/health-care/Brand-DH.png');
    }


    .dh {
        max-width: 878px;
        width: 100%;
        height: 500px;
        display: flex;
        flex-direction: column;
        justify-content: end;
        transition: height 1s;
        margin: 0 12px;
        padding: 0;

        @media only screen and (max-width:835px) {
            width: 343px;
            height: 160px;
        }

        .wrapper {
            .wrapper-content {
                height: 108px;
                transition: height 1s;
                padding: 32px 32px 0 32px;
                text-align: left;
                height: 99px;
                height: 109px;
                position: relative;

                .logo {
                    width: auto;
                    height: 48px;

                    @media only screen and (max-width:835px) {
                        position: absolute;
                        bottom: 16px;
                        left: 16px;
                        max-width: 120px;
                        height: 32px;
                    }
                }

                .desc {
                    padding: 24px 0 0px 0;
                    font-size: 16px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: normal;
                    text-align: left;
                    color: #fff;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    transition: 1s linear all;
                    opacity: 0;
                    visibility: hidden;

                    @media only screen and (max-width:835px) {
                        display: none;
                    }
                }

                .desc-link {
                    position: absolute;
                    bottom: 32px;
                    left: 32px;
                    opacity: 0;
                    visibility: hidden;

                    a {
                        font-size: 16px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.5;
                        letter-spacing: normal;
                        text-align: left;
                        color: #fff;
                    }

                    svg {
                        rotate: 180deg;
                    }

                    @media only screen and (max-width:835px) {
                        display: none;
                    }
                }
            }
        }
    }

    @media only screen and (min-width:836px) {
        .dh:hover {
            .wrapper {
                .wrapper-content {
                    height: 256px;
                    -webkit-backdrop-filter: blur(4px);
                    backdrop-filter: blur(4px);
                    background-color: rgba(0, 0, 0, .5);

                    .desc {
                        opacity: 1;
                        visibility: visible;
                    }

                    .desc-link {
                        animation: fadeIn 2s;
                        animation-delay: 0.2s;
                        animation-fill-mode: forwards;
                    }

                    @keyframes fadeIn {
                        from {
                            transform: translate(-100, 0);
                        }

                        to {
                            transform: translate(0, 0);
                            opacity: 1;
                            visibility: visible;
                            animation-play-state: paused
                        }
                    }
                }
            }
        }
    }
}
</style>