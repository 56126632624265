<template>
  <section id="community">
    <div class="bg">
      <div class="container">
        <h2>{{ t("aufc.community-title") }}</h2>
        <p>{{ t("aufc.community-desc") }}</p>
        <div class="logos">
          <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/DG@latest.png"/>
          <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/sub-brand/DF-Colour-Dark.svg"/>
          <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/sub-brand/DP-Colour-Dark-BG.svg"/>
        </div>
        <div class="flex">
          <div class="location">
            <img :src="`${ImageUrl}doo_group_sydney_headquarter.png`"/>
            <div class="content">
              <h3>Doo Group Sydney Headquarter</h3>
              <p><img :src="`${ImageUrl}icon_location.svg`"/>Suite 1302, Level 13, Tower 1, 475 Victoria Avenue, Chatswood NSW 2067.</p>
              <p><img :src="`${ImageUrl}icon_contact.svg`"/>Contact No.&nbsp;&nbsp;&nbsp;02 8801 3188</p>
            </div>
          </div>
          <div class="location">
            <img :src="`${ImageUrl}doo_group_chatswood.png`"/>
            <div class="content">
              <h3>Doo Group Chatswood Branch</h3>
              <p><img :src="`${ImageUrl}icon_location.svg`"/>Shop 3, Ground Floor, 408-414 Victoria Avenue, Chatswood NSW 2067</p>
              <p><img :src="`${ImageUrl}icon_contact.svg`"/>Contact No.&nbsp;&nbsp;&nbsp;02 8801 3186</p>
            </div>
          </div>
          <div class="location">
            <img :src="`${ImageUrl}doo_group_chinatown_branch.png`"/>
            <div class="content">
              <h3>Doo Group Chinatown Branch</h3>
              <p><img :src="`${ImageUrl}icon_location.svg`"/>Shop D1, Sussex Centre, 56 Dixon Street, Haymarket NSW 2000</p>
              <p><img :src="`${ImageUrl}icon_contact.svg`"/> Contact No.&nbsp;&nbsp;&nbsp;02 8801 3183</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import { useI18n } from 'vue-i18n';

const ImageUrl = "https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/aufc/"

const { t } = useI18n();

const socialMedia = ["instagram", "facebook", "linkedin", "youtube"]

</script>

<style lang="less">
@import '@/less/mediaQuery.less';
@tablet-screen: ~"only screen and (max-width:1050px)";
#community {
  >.bg {
    max-width:1920px;
    width:100%;
    padding-top:180px;
    padding-bottom:120px;
    margin:0 auto;
    background: #fafafa url("https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/aufc/bg.png") no-repeat center top;
    @media @mobile-screen {
      padding-top:140px;
      padding-bottom:120px;
    }
  }
  h2 {
    font-size: 76px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: normal;
    color: #3e3a39;
    margin-bottom:24px;
    text-align: center;
    @media @mobile-screen {
      font-size: 28px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: normal;
      color: #3e3a39;
      margin-bottom:16px;
    }
  }
  .container {
    > p {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: #3e3a39;
      max-width:580px;
      margin:0 auto 80px;
      @media @mobile-screen {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: #3e3a39;
        margin-bottom:32px;
      }
    }
  }
  img {
    max-width:100%;
  }
  .flex {
    display:flex;
    gap:24px;
    @media @mobile-screen {
      flex-direction: column;
      align-items: center;
    }
  }
  .left {
    min-width:452px;
    @media @tablet-screen {
      min-width: 340px;
    }
    .brand {
      position:relative;
      text-align: left;
      display: block;
      padding:72px 0 72px 96px;
      cursor: default;
       @media @tablet-screen {
        padding:28px 0 28px 67px;
      }
      &:first-child {
        &:before {
          top: 50%;
          height: 50%;
        }
      }
      &:last-child {
        &:before {
          top:initial;
          bottom:50%;
          height: 50%;
        }
      }
      &:before {
        content:'';
        position:absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 2px;
        background:#e03323;
      }
      &.active {
        .point {
          width: 22px;
          height: 22px;
          background:rgba(217, 29, 34, 0.9);
          border-radius:50px;
          border:6px solid #faebeb;
          margin-top:-11px;
          &:before {
            left: -63px;
            @media @tablet-screen {
              left: -36px;
              width: 27px;
            }
          }
        }
      }
      img {
        margin-bottom:16px;
         @media @tablet-screen {
          max-width:166px;
        }
        &.doofinancial {
           @media @tablet-screen {
            max-width:175px;
          }
        }
        &.doofinancial {
           @media @tablet-screen {
            max-width:127.8px;
          }
        }
      }
      h3 {
        margin-bottom:4px;
        font-family: Gravity;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: normal;
        text-align: left;
        color: #3e3a39;
         @media @tablet-screen {
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: left;
          color: #3e3a39;
        }
      }
      p {
        min-height:16px;
        margin-bottom:16px;
         @media @tablet-screen {
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.43;
          letter-spacing: normal;
          text-align: left;
          color: #3e3a39;
          min-height:20px;
          margin-bottom:4px;
        }
      }
      .point {
        width: 0;
        height: 0;
        border:initial;
        border-top: 8px solid transparent;
        border-left: 16px solid #e03323;
        border-bottom: 8px solid transparent;
        background:none;
        border-radius:initial;
        position:absolute;
        left: 57px;
        top:50%;
        margin-top:-9px;
        z-index: -1;
        @media @tablet-screen {
          left: 30px;
        }
        &:before {
          content:'';
          position:absolute;
          top:50%;
          margin-top: -2px;
          height:3px;
          width:47px;
          background:#e03323;
          left: -73px;
          @media @tablet-screen {
            width: 25px;
            left: -46px;
          }
        }
      }
    }
  }
  .right {
    position:relative;
    height: fit-content;
    @media @mobile-screen {
      max-width: 353px;
    }
    .group {
      display:none;
      position:absolute;
      left:0;
      top:0;
      width:100%;
      height:100%;
      &.active {
        display:block;
      }
      .location {
        width: fit-content;
        position:absolute;
        &.chatswood {
          right: 0;
          bottom: 336px;
          @media only screen and (max-width:1430px) and (min-width: 834px) {
            right: -1vw;
            bottom: 23vw;
          }
          @media @mobile-screen {
            right: 0;
            bottom: 43.5%;
          }
          .point {
            @media @mobile-screen {
              right: 18px;
              left: initial;
            }
          }
        }
        &.dg-australia {
          right: 96px;
          bottom: 295px;
          @media only screen and (max-width:1430px) and (min-width: 834px) {
            right: 5.9vw;
            bottom: 20vw;
          }
          @media @mobile-screen {
            right: 13.5%;
            bottom: 35%;
          }
          .point {
            left: initial;
            right: -17px;
            bottom: 50%;
            margin-bottom: -3px;
            @media only screen and (max-width:1430px) and (min-width: 834px) {
              right: -1.5vw;
              margin-bottom: -0.2vw;
            }
            @media @mobile-screen {
              right: -14px;
            }
          }
        }
        &.dg-service-center {
          bottom: 255px;
          right: -66px;
          @media only screen and (max-width:1430px) and (min-width: 834px) {
            right: -0.1vw;
            bottom: 17.3vw;
          }
          @media @mobile-screen {
            bottom: 26.5%;
            right: 0%;
          }
          .point {
            top: -18px;
            bottom: initial;
            @media only screen and (max-width:1430px) and (min-width: 834px) {
              top: -1.3vw;
              left: initial;
              right: 3.2vw;
            }
            @media @mobile-screen {
              top: -14px;
              left: initial;
              right: 17px;
            }
          }
        }
      }
      h4 {
        padding:2px 16px;
        background:#e32329;
        border-radius: 24px;
        font-size: 20px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.3;
        letter-spacing: normal;
        text-align: right;
        color: rgba(255, 255, 255, 0.95);
        width: fit-content;
        @media only screen and (max-width:1430px) and (min-width: 834px) {
          font-size: 1.35vw;
          padding: 0.1vw 1.18vw;
        }
        @media @mobile-screen {
          padding:1px 10px;
          font-size: 12px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: normal;
          text-align: right;
        }
      }
      p {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: #e32329;
        @media only screen and (max-width:1430px) and (min-width: 834px) {
          font-size: 1.1vw;
        }
        @media @mobile-screen {
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: normal;
          text-align: center;
          color: #e32329;
        }
      }
      .point {
        width: 8px;
        height: 8px;
        background-color: #e32329;
        border-radius:10px;
        position:absolute;
        left: 50%;
        bottom: -18px;
        @media only screen and (max-width:1430px) and (min-width: 834px) {
          width: 0.6vw;
          height: 0.6vw;
          bottom: -1.3vw;
        }
        @media @mobile-screen {
          width: 6px;
          height: 6px;
          bottom: -14px;
        }
        &:before {
          content:'';
          width:16px;
          height:16px;
          border:1px solid #e32329;
          border-radius:20px;
          position: absolute;
          left: 50%;
          top: 50%;
          translate: -50% -50%;
          @media only screen and (max-width:1430px) and (min-width: 834px) {
            width: 1.2vw;
            height: 1.2vw;
          }
          @media @mobile-screen {
            width: 10px;
            height: 10px;
          }
        }
      }
    }
  }
  .logos {
    display: flex;
    justify-content: space-between;
    max-width: 900px;
    margin: auto;
    margin-bottom: 99px;
    flex-wrap: wrap;
    
    @media screen and (max-width: 775px) {
      margin-bottom: 81px;
      max-width: 222px;
      gap: 36px;
    }

  }
  .location {
    text-align: left;
    width: 33.3%;
    background:#fff;
    @media screen and (max-width:834px) {
      width: 100%;
    }
    .content {
      padding:32px;
      @media screen and (max-width:834px) {
        padding:16px;
      }
    }
    > img {
      width:100%;
    }
    h3 {
      font-size: 32px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #3e3a39;
      text-transform: uppercase;
      margin-bottom:16px;
      @media screen and (max-width:834px) {
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: normal;
        text-align: left;
        color: #3e3a39;
      }
    }
    p {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: #3e3a39;
      padding-left:32px;
      margin-bottom:16px;
      position:relative;
      @media screen and (max-width:834px) {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #3e3a39;
      }
      img{
        position:absolute;
        top:0;
        left:0;
      }
    }
  }
}
</style>