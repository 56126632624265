<template>
    <section id="footer-top">
        <div class="container-links" v-if="isMobile == false">
            <div class="container">
                <div class="contact-us-container footer-col ">
                    <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/sub-brand/DG-Colour-Dark-Footer.png"
                        alt="doo-group" class="logo">
                    <p class="contact-us-txt" v-t="'footer.contact-us'"></p>
                    <p class="email"><img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/Email.svg" />
                        {{ $t('footer.email') }}</p>
                    <div class="wrapper-hp-top">
                        <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/Phone.svg" />

                        <p class="hp hp-top" v-t="'footer.hp-sg'"></p>
                        <p class="content" v-t="'footer.hp-sg-content'"></p>
                    </div>
                    <div class="wrapper-hp-bottom">
                        <p class="hp hp-bottom " v-t="'footer.hp-hk'"></p>
                        <p class="content" v-t="'footer.hp-hk-content'"></p>
                    </div>

                    <div class="social">
                        <p class="follow" v-t="'footer.follow-us'"></p>
                        <div class="social-logo">
                            <a href="https://www.linkedin.com/company/doo-holding-group-limited/" target="_blank"
                                class="icon-wrapper">
                                <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/LinkedIn.svg"
                                    alt="linkedIn" class="linkedIn logo white">
                                <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/LinkedIn-red.svg"
                                    alt="linkedIn" class="linkedIn logo red">
                            </a>
                            <a href="https://www.facebook.com/DooGroup" target="_blank" class="icon-wrapper">
                                <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/Facebook.svg"
                                    alt="facebook" class="facebook logo white">
                                <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/Facebook-red.svg"
                                    alt="facebook" class="facebook logo red">
                            </a>
                            <a href="https://www.instagram.com/doo_group/" target="_blank" class="icon-wrapper">
                                <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/Instagram.svg"
                                    alt="instagram" class="instagram logo white">
                                <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/Instagram-red.svg"
                                    alt="instagram" class="instagram logo red">
                            </a>
                            <a href="https://www.youtube.com/@doogroup6728" target="_blank" class="icon-wrapper">
                                <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/YouTube.svg"
                                    alt="youtube" class="youtube logo white">
                                <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/YouTube-red.svg"
                                    alt="youtube" class="youtube logo red">
                            </a>
                        </div>
                    </div>
                </div>
                <div class="wrapper">
                    <div class="column">
                        <!--div class="container-links-contact footer-col">
                            <div class="list-content col-1 ">
                                <a href="/" v-t="'footer.brand'"></a>
                                <a href="/" v-t="'footer.overview'"></a>
                                <a href="/" v-t="'footer.brokerage'"></a>
                                <a href="/" v-t="'footer.wealth-management'"></a>
                                <a href="/" v-t="'footer.payment-exchange'"></a>
                                <a href="/" v-t="'footer.finance'"></a>
                            </div>
                        </div-->
                        <div class="container-links-contact footer-col">
                            <div class="list-content col-1 ">
                                <p v-t="'footer.about-us'"></p>
                                <RouterLink :to="`/${$i18n.locale}/groupIntroduction`"
                                    v-t="'navigation.group-introduction'"></RouterLink>
                                <RouterLink :to="`/${$i18n.locale}/milestone`" v-t="'navigation.milestone'">
                                </RouterLink>
                                <RouterLink :to="`/${$i18n.locale}/license`" v-t="'navigation.regulatory-licenses'">
                                </RouterLink>
                                <RouterLink :to="`/${$i18n.locale}/globalOffice`"
                                    v-t="'navigation.global-operational-centers'"></RouterLink>
                                <a href="https://career.doo.com/career/" v-t="'navigation.join-us'"></a>
                            </div>
                        </div>
                        <div class="container-links-contact footer-col">
                            <div class="list-content col-1 ">
                                <p v-t="'navigation.business-and-brand'"></p>
                                <RouterLink :to="`/${$i18n.locale}/businessoverview`" v-t="'navigation.business-overview'">
                                </RouterLink>
                                <RouterLink :to="`/${$i18n.locale}/brokerage`" v-t="'navigation.brokerage'">
                                </RouterLink>
                                <RouterLink :to="`/${$i18n.locale}/wealthmanagement`" v-t="'navigation.wealth-management'">
                                </RouterLink>
                                <RouterLink :to="`/${$i18n.locale}/property`" v-t="'navigation.property'"></RouterLink>
                                <RouterLink :to="`/${$i18n.locale}/paymentexchange`" v-t="'navigation.payment-exchange'">
                                </RouterLink>
                                <RouterLink :to="`/${$i18n.locale}/fintech`" v-t="'navigation.finTech'"></RouterLink>
                                <RouterLink :to="`/${$i18n.locale}/financialeducation`"
                                    v-t="'navigation.financial-education'"></RouterLink>
                                <RouterLink :to="`/${$i18n.locale}/healthcare`" v-t="'navigation.healthcare'">
                                </RouterLink>
                                <RouterLink :to="`/${$i18n.locale}/consult`" v-t="'navigation.consulting'"></RouterLink>
                                <RouterLink :to="`/${$i18n.locale}/cloud`" v-t="'navigation.cloud'"></RouterLink>
                                <RouterLink :to="`/${$i18n.locale}/digital-marketing`" v-t="'navigation.digital-marketing'">
                                </RouterLink>
                            </div>
                        </div>
                        <div class="container-links-contact footer-col">
                            <div class="list-content col-1 ">
                                <p v-t="'footer.partnership'"></p>
                                <a href="https://www.doo.com/en/partnership/manutd" v-t="'footer.Mu'"></a>
                                <a v-if="$i18n.locale === 'en'"
                                    :href="`https://www.doo.com/${$i18n.locale}/partnership/manutd`" v-t="'footer.Au'"></a>
                            </div>
                        </div>
                        <div class="container-links-contact footer-col">
                            <div class="list-content col-1 ">
                                <p v-t="'footer.news'"></p>
                                <!--a href="/" v-t="'footer.our-partners'"></a-->
                                <a href="https://blog.doo.com/" v-t="'footer.media'"></a>
                                <!--a :href="`${locationOrigin}/en/partnership/aufc`">AUFC</a-->
                                <!--a href="/" v-t="'footer.Au'"></a>
                                <a href="/" class="last-link" v-t="'footer.ESG'"></a-->
                                <p></p>
                                <RouterLink :to="`/${$i18n.locale}/contactus`" v-t="'footer.contact-us'"></RouterLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-links-mobile" v-else>
            <div class="wrapper-links">
                <div class="contact-us-container footer-col ">
                    <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/sub-brand/DG-Colour-Dark-Footer.png"
                        alt="doo-group" class="logo">
                    <p class="contact-us-txt" v-t="'footer.contact-us'"></p>
                    <p class="email"><img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/Email.svg" />
                        {{ $t('footer.email') }}</p>
                    <div class="wrapper-hp-top">
                        <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/Phone.svg" />
                        <p class="hp hp-top" v-t="'footer.hp-sg'"></p>
                        <p class="content" v-t="'footer.hp-sg-content'"></p>
                    </div>
                    <div class="wrapper-hp-bottom">
                        <p class="hp hp-bottom " v-t="'footer.hp-hk'"></p>
                        <p class="content" v-t="'footer.hp-hk-content'"></p>
                    </div>

                    <div class="social">
                        <p class="follow" v-t="'footer.follow-us'"></p>
                        <div class="social-logo">
                            <a href="https://www.linkedin.com/company/doo-holding-group-limited/" target="_blank">
                                <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/LinkedIn.svg"
                                    alt="linkedIn" class="linkedIn logo">
                            </a>
                            <a href="https://www.facebook.com/DooGroup" target="_blank">
                                <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/Facebook.svg"
                                    alt="facebook" class="facebook logo">
                            </a>
                            <a href="https://www.instagram.com/doo_group/" target="_blank">
                                <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/Instagram.svg"
                                    alt="instagram" class="instagram logo">
                            </a>
                            <a href="https://www.youtube.com/@doogroup6728" target="_blank">
                                <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/YouTube.svg"
                                    alt="youtube" class="youtube logo">
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="logos-mobile">
                <!-- doogroup -->
                <a href="https://doo.com/en" target="_blank" class="icon-wrapper">
                    <img class="doo-group logo"
                        src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/new-sub-brand/sub-brand_DG_off_mb.png"
                        alt="doo-group">
                </a>
                <!-- financial -->
                <a href="https://www.doofinancial.com/" target="_blank" class="icon-wrapper">
                    <img class="doo-financial logo"
                        src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/new-sub-brand/sub-brand_DF_off_mb.png"
                        alt="doo-financial">
                </a>
                <!-- clearing -->
                <a href="https://www.dooclearing.com/" target="_blank" class="icon-wrapper">
                    <img class="doo-clearing logo"
                        src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/new-sub-brand/sub-brand_DC_off_mb.png"
                        alt="doo-clearing">
                </a>
                <!-- wealth -->
                <a href="https://doowealth.com/" target="_blank" class="icon-wrapper">
                    <img class="doo-wealth logo"
                        src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/new-sub-brand/sub-brand_DW_off_mb.png"
                        alt="doo-wealth">
                </a>
                <!-- property -->
                <a href="https://property.doo.com/" target="_blank" class="icon-wrapper">
                    <img class="doo-property logo"
                        src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/new-sub-brand/sub-brand_DPR_off_mb.png"
                        alt="doo-property">
                </a>
                <!-- consulting -->
                <a href="https://consulting.doo.com/" target="_blank" class="icon-wrapper">
                    <img class="consulting logo"
                        src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/new-sub-brand/sub-brand_DCN_off_mb.png"
                        alt="consulting">
                </a>
                <!-- doopayment -->
                <a href="https://www.doopayment.com/" target="_blank" class="icon-wrapper">
                    <img class="finpoints logo"
                        src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/new-sub-brand/sub-brand_DPM_off_mb.png"
                        alt="finpoints">
                </a>
                <!-- health -->
                <a href="https://health.doo.com/" target="_blank" class="icon-wrapper">
                    <img class="health-care logo"
                        src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/new-sub-brand/sub-brand_DH_off_mb.png"
                        alt="health care">
                </a>
                <!-- dooacademy -->
                <a href="https://www.dooacademy.com/" target="_blank" class="icon-wrapper">
                    <img class="financial-education logo"
                        src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/new-sub-brand/sub-brand_DA_off_mb.png"
                        alt="financial education">
                </a>
                <!-- cloud -->
                <a href="https://cloud.doo.com/" target="_blank" class="icon-wrapper">
                    <img class="cloud logo"
                        src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/new-sub-brand/sub-brand_DCD_off_mb.png"
                        alt="cloud">
                </a>
                <!-- digitalplus -->
                <a href="https://www.doodigitalplus.com/" target="_blank" class="icon-wrapper txt-left">
                    <img class="digital-plus logo"
                        src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/new-sub-brand/sub-brand_DDP_off_mb.png"
                        alt="digital-plus">
                </a>
                <!-- finpoints -->
                <a href="https://www.finpoints.com/" target="_blank" class="icon-wrapper">
                    <img class="finpoints logo"
                        src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/new-sub-brand/sub-brand_FP_off_mb.png"
                        alt="finpoints">
                </a>
            </div>

            <div class="border" style="margin-top:0;"></div>

            <div class="container-links-contact footer-col">
                <div class="list-content col-1">
                    <div class="txt-wrapper" @click="toggleAboutUs">
                        <p class="title" v-t="'footer.about-us'"></p>
                        <img class="icon-dropdown"
                            src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/Dropdown_dark.svg"
                            alt="dropdown">
                    </div>
                    <div class="container-dropdown" v-if="aboutUs">
                        <RouterLink :to="`/${$i18n.locale}/groupIntroduction`" v-t="'navigation.group-introduction'">
                        </RouterLink>
                        <RouterLink :to="`/${$i18n.locale}/milestone`" v-t="'navigation.milestone'"></RouterLink>
                        <RouterLink :to="`/${$i18n.locale}/license`" v-t="'navigation.regulatory-licenses'">
                        </RouterLink>
                        <RouterLink :to="`/${$i18n.locale}/globalOffice`" v-t="'navigation.global-operational-centers'">
                        </RouterLink>
                        <a href="https://career.doo.com/career/" v-t="'navigation.join-us'"></a>
                    </div>
                </div>
            </div>

            <div class="border"></div>

            <div class="container-links-contact footer-col">
                <div class="list-content col-1">
                    <div class="txt-wrapper" @click="toggleBusinessAndBrand">
                        <p class="title" v-t="'navigation.business-and-brand'"></p>
                        <img class="icon-dropdown"
                            src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/Dropdown_dark.svg"
                            alt="dropdown">
                    </div>
                    <div class="container-dropdown" v-if="businessAndBrand">
                        <RouterLink :to="`/${$i18n.locale}/businessverview`" v-t="'navigation.business-overview'">
                        </RouterLink>
                        <RouterLink :to="`/${$i18n.locale}/brokerage`" v-t="'navigation.brokerage'"></RouterLink>
                        <RouterLink :to="`/${$i18n.locale}/wealthmanagement`" v-t="'navigation.wealth-management'">
                        </RouterLink>
                        <RouterLink :to="`/${$i18n.locale}/property`" v-t="'navigation.property'"></RouterLink>
                        <RouterLink :to="`/${$i18n.locale}/paymentexchange`" v-t="'navigation.payment-exchange'">
                        </RouterLink>
                        <RouterLink :to="`/${$i18n.locale}/finTech`" v-t="'navigation.finTech'"></RouterLink>
                        <RouterLink :to="`/${$i18n.locale}/financialeducation`" v-t="'navigation.financial-education'">
                        </RouterLink>
                        <RouterLink :to="`/${$i18n.locale}/healthcare`" v-t="'navigation.healthcare'"></RouterLink>
                        <RouterLink :to="`/${$i18n.locale}/consult`" v-t="'navigation.consulting'"></RouterLink>
                        <RouterLink :to="`/${$i18n.locale}/cloud`" v-t="'navigation.cloud'"></RouterLink>
                        <RouterLink :to="`/${$i18n.locale}/digital-marketing`" v-t="'navigation.digital-marketing'">
                        </RouterLink>
                    </div>
                </div>
            </div>

            <div class="border"></div>

            <div class="container-links-contact footer-col">
                <div class="list-content col-2 ">
                    <div class="txt-wrapper" @click="togglePartnership">
                        <p class="title" v-t="'footer.partnership'"></p>
                        <img class="icon-dropdown"
                            src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/Dropdown_dark.svg"
                            alt="dropdown">
                    </div>
                    <div class="container-dropdown" v-if="partnershipSection">
                        <!-- <a href="/" v-t="'footer.our-partners'"></a> -->
                        <a href="https://www.doo.com/en/partnership/manutd" v-t="'footer.Mu'"></a>
                        <a v-if="$i18n.locale === 'en'" :href="`${locationOrigin}/${$i18n.locale}/partnership/aufc`"
                            v-t="'footer.Au'"></a>
                    </div>
                </div>
            </div>
            <div class="border"></div>

            <div class="container-links-contact footer-col">
                <div class="list-content col-2 ">
                    <div class="txt-wrapper" @click="toggleNews">
                        <p class="title" v-t="'footer.news'"></p>
                        <img class="icon-dropdown"
                            src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/Dropdown_dark.svg"
                            alt="dropdown">
                    </div>
                    <div class="container-dropdown" v-if="newsSection">
                        <a href="https://blog.doo.com/" v-t="'footer.media'"></a>
                    </div>
                </div>


            </div>
            <div class="border"></div>
            <div class="esg-single" style="padding-left:0;">
                <RouterLink :to="`/${$i18n.locale}/contactus`" v-t="'footer.contact-us'"></RouterLink>
            </div>

            <div class="border"></div>

        </div>
        <div class="logos" v-if="isMobile == false">
            <div class="container">
                <div class="wrapper">
                    <!--  # doo group -->
                    <div class="icon-wrapper">
                        <a href="https://doo.com/en" target="_blank" class="">
                            <img class="doo-group logo"
                                src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/sub-brand/footer-doo-group-off.png"
                                alt="doo-group">
                            <img class="doo-group logo"
                                src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/sub-brand/footer-doo-group-on.png"
                                alt="doo-group">
                        </a>
                    </div>
                    <!--  # doo financial -->
                    <div class="icon-wrapper">
                        <a href="https://www.doofinancial.com/" target="_blank" class="">
                            <img class="doo-financial logo"
                                src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/new-sub-brand/sub-brand_DF_off.png"
                                alt="doo-financial">
                            <img class="doo-financial logo"
                                src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/new-sub-brand/sub-brand_DF_on.png"
                                alt="doo-financial">
                        </a>
                    </div>
                    <!--  # doo clearing -->
                    <div class="icon-wrapper">
                        <a href="https://www.dooclearing.com/" target="_blank" class="">
                            <img class="doo-clearing logo"
                                src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/new-sub-brand/sub-brand_DC_off.png"
                                alt="doo-clearing">
                            <img class="doo-clearing logo"
                                src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/new-sub-brand/sub-brand_DC_on.png"
                                alt="doo-clearing">
                        </a>
                    </div>

                    <!--  # doo wealth -->
                    <div class="icon-wrapper">
                        <a href="https://doowealth.com/" target="_blank" class="">
                            <img class="doo-wealth logo"
                                src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/new-sub-brand/sub-brand_DW_off.png"
                                alt="doo-wealth">
                            <img class="doo-wealth logo"
                                src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/new-sub-brand/sub-brand_DW_on.png"
                                alt="doo-wealth">
                        </a>
                    </div>
                    <!--  # property -->
                    <div class="icon-wrapper">
                        <a href="https://property.doo.com/" target="_blank" class="">
                            <img class="property logo"
                                src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/new-sub-brand/sub-brand_DPR_off.png"
                                alt="property">
                            <img class="property logo"
                                src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/new-sub-brand/sub-brand_DPR_on.png"
                                alt="property">
                        </a>
                    </div>
                    <!--  # consulting -->
                    <div class="icon-wrapper">
                        <a href="https://consulting.doo.com/" target="_blank" class="">
                            <img class="consulting logo"
                                src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/new-sub-brand/sub-brand_DCN_off.png"
                                alt="consulting">
                            <img class="consulting logo"
                                src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/new-sub-brand/sub-brand_DCN_on.png"
                                alt="consulting">
                        </a>
                    </div>
                    <!--  # doo payment -->
                    <div class="icon-wrapper">
                        <a href="https://www.doopayment.com/" target="_blank" class="">
                            <img class="finpoints logo"
                                src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/new-sub-brand/sub-brand_DPM_off.png"
                                alt="finpoints">
                            <img class="finpoints logo"
                                src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/new-sub-brand/sub-brand_DPM_on.png"
                                alt="finpoints">
                        </a>
                    </div>
                    <!--  # health -->
                    <div class="icon-wrapper">
                        <a href="https://health.doo.com/" target="_blank" class="">
                            <img class="health-care logo"
                                src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/new-sub-brand/sub-brand_DH_off.png"
                                alt="health care">
                            <img class="health-care logo"
                                src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/new-sub-brand/sub-brand_DH_on.png"
                                alt="health care">
                        </a>
                    </div>
                    <!--  # doo academy -->
                    <div class="icon-wrapper">
                        <a href="https://www.dooacademy.com/" target="_blank" class="">
                            <img class="financial-education logo"
                                src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/new-sub-brand/sub-brand_DA_off.png"
                                alt="financial education">
                            <img class="financial-education logo"
                                src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/new-sub-brand/sub-brand_DA_on.png"
                                alt="financial education">
                        </a>
                    </div>
                    <!--  # cloud -->
                    <div class="icon-wrapper">
                        <a href="https://cloud.doo.com/" target="_blank" class="">
                            <img class="cloud logo"
                                src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/sub-brand/footer-doo-cloud-off.png"
                                alt="cloud">
                            <img class="cloud logo"
                                src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/sub-brand/footer-doo-cloud-on.png"
                                alt="cloud">
                        </a>
                    </div>
                    <!--  # digitalplus -->
                    <div class="icon-wrapper">
                        <a href="https://www.doodigitalplus.com/" target="_blank" class="">
                            <img class="digital-plus logo"
                                src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/sub-brand/footer-doo-digital-plus-off.png"
                                alt="digital-plus">
                            <img class="digital-plus logo"
                                src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/sub-brand/footer-doo-digital-plus-on.png"
                                alt="digital-plus">
                        </a>
                    </div>
                    <!--  # finpoints -->
                    <div class="icon-wrapper">
                        <a href="https://www.finpoints.com/" target="_blank" class="">
                            <img class="finpoints logo"
                                src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/new-sub-brand/sub-brand_FP_off.png"
                                alt="finpoints">
                            <img class="finpoints logo"
                                src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/new-sub-brand/sub-brand_FP_on.png"
                                alt="finpoints">
                        </a>
                    </div>
                </div>
            </div>
        </div>

    </section>
</template>

<script>

export default {
    setup() {
        return {
            locationOrigin: window.location.origin
        };
    },
    data() {
        return {
            isMobile: false,
            aboutUs: true,
            businessAndBrand: false,
            brandSection: false,
            partnershipSection: false,
            newsSection: false
        }
    },
    methods: {
        checkMobile() {
            if (window.innerWidth <= 835) {
                this.isMobile = true
            } else {
                this.isMobile = false
            }
        },
        toggleAboutUs() {
            this.aboutUs = !this.aboutUs
        },
        toggleBusinessAndBrand() {
            this.businessAndBrand = !this.businessAndBrand
        },
        toggleBrand() {
            this.brandSection = !this.brandSection
        },
        togglePartnership() {
            this.partnershipSection = !this.partnershipSection
        },
        toggleNews() {
            this.newsSection = !this.newsSection
        },

    },
    mounted() {
        this.checkMobile()
        window.addEventListener("resize", this.checkMobile);
    }
}
</script>

<style lang="less" scoped>
@import '../../../less/mediaQuery.less';

#footer-top {
    background-color: #161414;
    padding: 0 0 40px;

    @media only screen and(max-width: 836px) {
        height: auto;
    }

    .container {
        display: flex;
        justify-content: space-between;
    }

    .container-links {
        padding-top: 40px;

        .wrapper {
            flex: 1;

            .column {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
            }
        }


        .contact-us-container {
            display: inline-block;
            text-align: left;
            vertical-align: top;
            flex: 0 0 30%;

            .logo {
                width: 224px;
                height: 48px;
            }

            .contact-us-txt {

                font-size: 14px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                color: #9e9e9f;
                padding-top: 48px;
                padding-bottom: 19px;
                margin: 0;
            }

            .email {
                font-family: SegoeUI;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                color: #fff;
                display: flex;
                align-items: center;
                margin: 0;
                margin-bottom: 24px;

                img {
                    width: 16px;
                    height: 16px;
                    margin-right: 16px;
                }
            }

            .hp {

                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                color: #9e9e9f;
                display: flex;
                align-items: center;
                margin: 0;

            }

            .wrapper-hp-top {
                display: flex;
                align-items: flex-start;
                margin-bottom: 16px;

                img {
                    width: 16px;
                    height: 16px;
                    margin-right: 16px;
                }

                .content {
                    font-family: SegoeUI;
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: normal;
                    color: #fff;
                    padding-left: 8px;
                }

                .content {
                    font-family: SegoeUI;
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: normal;
                    color: #fff;
                    padding-left: 8px;
                    margin-top: 0;
                }

            }

            .wrapper-hp-bottom {
                display: flex;
                align-items: start;

                .hp-bottom {
                    margin-bottom: 48px;
                    margin-top: 0px;
                    padding-left: 31px;
                }

                .content {
                    display: flex;
                    align-items: start;
                    font-family: SegoeUI;
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: normal;
                    color: #fff;
                    padding-left: 8px;
                    margin-top: 0;
                }
            }

            .social {
                display: flex;
                flex-direction: column;
                align-items: start;
                justify-content: center;
                margin-bottom: 120px;

                @media only screen and(max-width:836px) {
                    margin-bottom: 0px;
                }

                .follow {
                    margin-bottom: 0;

                    font-size: 14px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: normal;
                    color: #9e9e9f;
                    padding-bottom: 15px;
                    margin-bottom: 0;
                    margin-top: 0;
                }

                .social-logo {
                    .logo {
                        width: 24px;
                        height: 24px;
                        margin-right: 24px;
                    }



                    .logo:nth-child(1) {
                        padding-left: 0;
                    }
                }
            }
        }





        .container-links-contact {
            display: inline-block;

            .list-content {
                display: flex;
                justify-content: center;
                flex-direction: column;
                text-align: left;
                width: 100%;

                a,
                p {

                    font-size: 14px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: normal;
                    color: #9e9e9f;
                    text-decoration: none;
                    padding-bottom: 16px;
                    display: block;

                    &:not(:first-child) {
                        color: #fff;
                    }

                    &:hover,
                    &focus {
                        color: #e03323;
                    }

                }

                p:hover {
                    color: #9e9e9f;
                }

                .last-link {
                    padding-bottom: 0;
                    padding-top: 40px;
                }

                .last-link-contact {
                    padding-bottom: 0;
                    padding-top: 116px;
                }
            }
        }
    }

    .icon-wrapper {
        display: inline-block;

        @media only screen and (min-width: 835px) {
            &:hover {
                img {
                    &:first-child {
                        display: none;
                    }

                    &:last-child {
                        display: block;
                    }
                }
            }

            img {
                &:last-child {
                    display: none;
                }
            }
        }



    }

    .container-links-mobile {
        padding: 0 16px;

        .wrapper-links {
            padding-top: 40px;
            display: flex;
            justify-content: flex-start;
            padding-bottom: 51px;

            .contact-us-container {
                display: flex;
                flex-direction: column;
                align-items: start;

                .logo {
                    width: 224px;
                    height: 48px;

                    @media @mobile-screen {
                        width: 168px;
                        height: auto;
                    }
                }

                .contact-us-txt {

                    font-size: 14px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: normal;
                    color: #9e9e9f;
                    padding-top: 48px;
                    padding-bottom: 19px;
                    margin: 0;
                }

                .email {
                    font-family: SegoeUI;
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: normal;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    margin: 0;
                    margin-bottom: 24px;

                    img {
                        width: 16px;
                        height: 16px;
                        margin-right: 16px;
                    }
                }

                .hp {

                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: normal;
                    color: #9e9e9f;
                    display: flex;
                    align-items: center;
                    margin: 0;

                }

                .wrapper-hp-top {
                    display: flex;
                    align-items: flex-start;

                    img {
                        width: 16px;
                        height: 16px;
                        margin-right: 16px;
                    }

                    .hp-top {
                        margin-bottom: 16px;
                    }

                    .content {
                        font-family: SegoeUI;
                        font-size: 14px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.5;
                        letter-spacing: normal;
                        color: #fff;
                        padding-left: 8px;
                        margin-bottom: 16px;
                    }
                }

                .wrapper-hp-bottom {
                    display: flex;
                    align-items: start;

                    .hp-bottom {
                        margin-bottom: 48px;
                        margin-top: 0px;
                        padding-left: 32px;
                    }

                    .content {
                        display: flex;
                        align-items: start;
                        font-family: SegoeUI;
                        font-size: 14px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.5;
                        letter-spacing: normal;
                        color: #fff;
                        padding-left: 8px;
                        margin-top: 0;
                    }
                }

                .social {
                    display: flex;
                    flex-direction: column;
                    align-items: start;
                    justify-content: center;
                    margin-bottom: 100px;

                    @media only screen and(max-width:836px) {
                        margin-bottom: 0px;
                    }

                    .follow {
                        margin-bottom: 0;

                        font-size: 14px;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.5;
                        letter-spacing: normal;
                        color: #9e9e9f;
                        padding-bottom: 15px;
                        margin-bottom: 0;
                        margin-top: 0;
                    }

                    .social-logo {
                        .logo {
                            width: 24px;
                            height: 24px;
                            margin-right: 24px;
                        }

                        .logo:nth-child(1) {
                            padding-left: 0;
                        }
                    }
                }
            }
        }

        .logos-mobile {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 24px 51px;
            justify-content: start;
            align-items: center;
            width: 100%;
            height: 100%;
            padding-bottom: 40px;

            @media screen and (max-width: 520px) {
                grid-template-columns: repeat(2, 1fr);
            }

            .icon-wrapper {
                max-width: 148px;
                width: 100%;
                height: 28px;
                display: block;

                &:nth-child(1) {
                    width: 115px;
                    height: 28px;
                }

                &:nth-child(2) {
                    width: 148px;
                    height: 28px;
                }

                &:nth-child(3) {
                    width: 115px;
                    height: 28px;
                }

                &:nth-child(4) {
                    width: 127px;
                    height: 28px;
                }

                &:nth-child(5) {
                    width: 121px;
                    height: 28px;
                }

                &:nth-child(6) {
                    width: 138px;
                    height: 28px;
                }

                &:nth-child(7) {
                    width: 87.4px;
                    height: 27.8px;
                }

                &:nth-child(8) {
                    width: 111.2px;
                    height: 27.8px;
                }

                &:nth-child(9) {
                    width: 74.9px;
                    height: 24px;
                }

                &:nth-child(10) {
                    width: 123px;
                    height: 28px;
                }

                &:nth-child(11) {
                    width: 79px;
                    height: 28px;
                }

                &:nth-child(12) {
                    width: 81.4px;
                    height: 27.8px;
                }
            }


            .logo {
                width: 100%;
                height: 100%;
                text-align: left;
                object-fit: contain;
            }
        }

        .txt-left {
            text-align: left;
        }

        .border {
            height: 1px;
            margin-top: 16px;
            background-color: #fff;
            opacity: 0.2;
        }

        .esg-single {
            // margin: 14px 0;
            // padding-bottom: 0 !important;
            width: 100%;
            text-align: left;

            a {
                font-size: 14px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                color: #9e9e9f;
                text-decoration: none;
            }

            padding-left: 16px;
            padding-top: 16px;

        }

        .container-links-contact {

            .list-content {
                display: flex;
                justify-content: center;
                flex-direction: column;
                text-align: left;
                padding-top: 16px;

                .txt-wrapper {
                    display: flex;
                    justify-content: space-between;
                    cursor: pointer;

                    .title {
                        font-size: 14px;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.5;
                        letter-spacing: normal;
                        color: #9e9e9f;
                    }

                    p {
                        font-size: 14px;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.5;
                        letter-spacing: normal;
                        color: #fff;
                    }
                }

                .container-dropdown {
                    display: flex;
                    flex-direction: column;

                    a {
                        &:first-child {
                            padding-top: 16px;
                        }

                        font-size: 14px;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.5;
                        letter-spacing: normal;
                        text-decoration: none;
                        padding-bottom: 16px;
                        color: #fff;

                        &:last-child {
                            padding-bottom: 0px;
                        }
                    }
                }



                .last-link {
                    padding-bottom: 0;
                    padding-top: 40px;
                }


                .last-link-contact {
                    padding-bottom: 0;
                    padding-top: 116px;
                }


            }
        }
    }

    .logos {
        // display: flex;
        // align-items: center;
        // justify-content: start;

        .wrapper {
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            text-align: left;
            gap: 24px 51px;
            justify-items: start;
            align-items: center;
            margin-top: 24px;

            @media screen and (min-width:1024px) and (max-width: 1200px) {
                grid-template-columns: repeat(5, 1fr);
            }

            @media screen and (min-width:836px) and (max-width: 1023px) {
                grid-template-columns: repeat(4, 1fr);
            }
        }

        .logo {
            width: 100%;
            height: 100%;
            // object-fit: contain;
        }

        a {
            display: block;
            height: 100%;
        }
    }


}
</style>