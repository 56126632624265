import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from "pinia"
import i18n from './i18n'
import { plugin, defaultConfig } from '@formkit/vue'
import './less/main.less'
import 'vue3-carousel/dist/carousel.css'
import Vue3Lottie from 'vue3-lottie'

createApp(App)
.use(i18n)
.use(router)
.use(createPinia())
.use(plugin, defaultConfig )
.use(Vue3Lottie)
.mount('#app')
