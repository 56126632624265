<template>
    <section id="mobile-table">
        <div class="container-content">
            <div class="wrapper-list home">
                <RouterLink v-t="'navigation.home'" class="main" page="home" :to="`/${$i18n.locale}`" @click="closeNav()">
                </RouterLink>
            </div>
            <button class=" wrapper-list home" @click="toggleList('aboutus')">
                <div v-t="'navigation.about-us'" :class="dropdownMenus['aboutus'] ? 'active' : ''"></div>
                <svg xmlns="https://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"
                    :class="dropdownMenus['aboutus'] ? 'rotate-180' : '#000'">
                    <path :fill="dropdownMenus['aboutus'] ? '#e03323' : '#000'" d="m12 15l-5-5h10l-5 5Z" />
                </svg>
            </button>
            <div class="sub-menu" v-if="dropdownMenus['aboutus']">
                <RouterLink :to="`/${$i18n.locale}/groupIntroduction`" v-t="'navigation.group-introduction'"
                    @click="closeNav()"></RouterLink>
                <RouterLink :to="`/${$i18n.locale}/milestone`" v-t="'navigation.milestone'" @click="closeNav()">
                </RouterLink>
                <RouterLink :to="`/${$i18n.locale}/license`" v-t="'navigation.regulatory-licenses'" @click="closeNav()">
                </RouterLink>
                <RouterLink :to="`/${$i18n.locale}/globalOffice`" v-t="'navigation.global-operational-centers'"
                    @click="closeNav()">
                </RouterLink>
                <a href="https://career.doo.com/career/" v-t="'navigation.join-us'"></a>
            </div>
            <button class=" wrapper-list home" @click="toggleList('businessAndBrand')">
                <div v-t="'navigation.business-and-brand'" :class="dropdownMenus['businessAndBrand'] ? 'active' : ''"></div>
                <svg xmlns="https://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"
                    :class="dropdownMenus['businessAndBrand'] ? 'rotate-180' : '#000'">
                    <path :fill="dropdownMenus['businessAndBrand'] ? '#e03323' : '#000'" d="m12 15l-5-5h10l-5 5Z" />
                </svg>
            </button>
            <div class="sub-menu" v-if="dropdownMenus['businessAndBrand']">
                <RouterLink :to="`/${$i18n.locale}/businessoverview`" v-t="'navigation.business-overview'"
                    @click="closeNav()">
                </RouterLink>
                <RouterLink :to="`/${$i18n.locale}/brokerage`" v-t="'navigation.brokerage'" @click="closeNav()">
                </RouterLink>
                <RouterLink :to="`/${$i18n.locale}/wealthmanagement`" v-t="'navigation.wealth-management'"
                    @click="closeNav()">
                </RouterLink>
                <RouterLink :to="`/${$i18n.locale}/property`" v-t="'navigation.property'" @click="closeNav()">
                </RouterLink>
                <RouterLink :to="`/${$i18n.locale}/paymentexchange`" v-t="'navigation.payment-exchange'"
                    @click="closeNav()">
                </RouterLink>
                <RouterLink :to="`/${$i18n.locale}/fintech`" v-t="'navigation.finTech'" @click="closeNav()">
                </RouterLink>
                <RouterLink :to="`/${$i18n.locale}/financialeducation`" v-t="'navigation.financial-education'"
                    @click="closeNav()">
                </RouterLink>
                <RouterLink :to="`/${$i18n.locale}/healthcare`" v-t="'navigation.healthcare'" @click="closeNav()">
                </RouterLink>
                <RouterLink :to="`/${$i18n.locale}/consult`" v-t="'navigation.consulting'" @click="closeNav()">
                </RouterLink>
                <RouterLink :to="`/${$i18n.locale}/cloud`" v-t="'navigation.cloud'" @click="closeNav()">
                </RouterLink>
                <RouterLink :to="`/${$i18n.locale}/digital-marketing`" v-t="'navigation.digital-marketing'"
                    @click="closeNav()">
                </RouterLink>
            </div>
            <button class="wrapper-list home" @click="toggleList('partnership')">
                <div v-t="'navigation.partnership'" :class="dropdownMenus['partnership'] ? 'active' : ''"></div>
                <svg xmlns="https://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"
                    :class="dropdownMenus['partnership'] ? 'rotate-180' : '#000'">
                    <path :fill="dropdownMenus['partnership'] ? '#e03323' : '#000'" d="m12 15l-5-5h10l-5 5Z" />
                </svg>
            </button>
            <div class="sub-menu" v-if="dropdownMenus['partnership']">
                <a :href="`/${$i18n.locale === 'sc' ? 'zh' : $i18n.locale}/partnership/manutd`" v-t="'footer.Mu'"></a>
                <a v-if="$i18n.locale === 'en'" :href="`${locationOrigin}/en/partnership/aufc`" v-t="'footer.Au'"></a>
            </div>
            <button class="wrapper-list home" @click="toggleList('latestNews')">
                <div v-t="'navigation.news'" :class="dropdownMenus['latestNews'] ? 'active' : ''"></div>
                <svg xmlns="https://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"
                    :class="dropdownMenus['latestNews'] ? 'rotate-180' : '#000'">
                    <path :fill="dropdownMenus['latestNews'] ? '#e03323' : '#000'" d="m12 15l-5-5h10l-5 5Z" />
                </svg>
            </button>
            <div class="sub-menu" v-if="dropdownMenus['latestNews']">
                <a href="https://blog.doo.com/" v-t="'footer.media'"></a>

            </div>
            <div class="wrapper-list">
                <RouterLink :to="`/${$i18n.locale}/contactus`" v-t="'navigation.contact-us'" class="main"
                    :class="currentRoute.name === `contactus` ? 'active' : ``" @click="closeNav()">
                </RouterLink>
            </div>
        </div>

        <div div class=" border-bottom">
        </div>

        <div class="wrapper">
            <img class="globe" src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/Language-black.svg"
                alt="language-img">
            <p class="select-lang" v-t="'common.language-selection'"></p>
            <div class="dropdown">
                <div class="wrapper-content" @click="toggleLangBar(true)">
                    <p class="selected" v-t="`common.${this.$i18n.locale}`"></p>
                    <img class="arrow-down"
                        src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/Dropdown.svg" alt="arrow-down">
                </div>
                <div class="dropdown-content" v-if="showLangBar == true">
                    <button class="close" @click="toggleLangBar(false)">
                        <img class="close-icon"
                            src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/Close.svg" alt="close">
                    </button>
                    <p class="choose" v-t="'common.language-selection'"></p>
                    <div class="txt" @click="switchLanguage('en')" v-t="'common.en'"></div>
                    <div class="txt" @click="switchLanguage('sc')" v-t="'common.sc'"></div>
                    <div class="txt" @click="switchLanguage('tc')" v-t="'common.tc'"></div>
                </div>
            </div>
        </div>

    </section>
</template>

<script>
import { ref } from 'vue'

export default {
    setup() {
        return {
            locationOrigin: window.location.origin
        };
    },
    data() {
        return {
            dropdownMenus: {
                aboutus: ref(false),
                businessAndBrand: ref(false),
                latestNews: ref(false),
                partnership: ref(false)
            },
            showLangBar: ref(false),
            currentRoute: this.$router.currentRoute
        }
    },
    methods: {
        toggleList(targetList) {
            let currentList = false;
            for (let item in this.dropdownMenus) {
                if (targetList == item) {
                    this.dropdownMenus[item] = !this.dropdownMenus[item];
                    currentList = this.dropdownMenus[item];
                } else {
                    this.dropdownMenus[item] = false;
                }
            }
            return currentList;
        },
        toggleLangBar(status) {
            this.showLangBar = status
        },
        switchLanguage(lang) {
            const currentPath = window.location.pathname.split('/').slice(2)?.join('/');
            this.$router.push({ path: `/${lang}/${currentPath}` })
            this.$emit('closeNav', false)
            this.showLangBar = false;
        },
        closeNav() {
            this.$emit('closeNav', false)
        }

    }
}

</script>


<style lang="less" scoped>
@import '../../../less/mediaQuery.less';

.rotate-180 {
    rotate: 180deg;
}

#mobile-table {
    overflow: scroll;
    padding: 88px 16px 0px 16px;
    height: 100%;

    .container-content {

        .wrapper-list {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 32px;
            border: none;
            background-color: transparent;
            width: 100%;
            font-size: 16px;
            line-height: 24px;
            cursor: pointer;

            a {
                color: #3e3a39;
            }

            div {
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                color: #3e3a39;
            }

            button {
                border: none;
                background-color: transparent;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                color: #3e3a39;
                cursor: pointer;
            }

            .active {
                color: red;
            }

        }

        .sub-menu {
            padding-left: 32px;
            text-align: left;
            padding-bottom: 32px;

            a {
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                color: #3e3a39;
                width: 100%;
                display: block;
                margin-bottom: 32px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .border-bottom {
        height: 1px;
        background-color: rgba(158, 158, 159, 0.4);
    }

    .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: 6px 20px 6px 0;
        max-width: 1455px;
        margin: auto;

        @media only screen and (max-width:1600px) {
            max-width: none;
            padding-right: 80px;
            padding-left: 80px;
        }

        @media only screen and (max-width:1100px) {
            height: initial;
            padding: 32px 0;
        }



        .globe {
            width: 16px;
            height: 16px;
            margin-right: 8px;
        }

        .select-lang {

            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.43;
            letter-spacing: normal;
            color: #3e3a39;
            margin: 0;
        }

        .dropdown {
            position: initial !important;

            .wrapper-content {
                .selected {
                    padding-left: 8px;
                    position: relative;
                    white-space: nowrap;
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.43;
                    letter-spacing: normal;
                    color: #3e3a39;
                    margin: 0;
                }

                .selected::after {
                    content: url('https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/Dropdown_dark.svg');
                    width: 16px;
                    height: 16px;
                    top: -2px;
                    right: -20px;
                    position: absolute;
                }

                .arrow-down {
                    width: 16px;
                    height: 16px;
                }

                display: flex;
                align-items: center;
            }

            .wrapper-content:hover {
                cursor: pointer;
            }
        }

        .dropdown-content {
            display: block;
            position: absolute;
            background-color: #ffff;
            width: 100%;
            height: 100%;
            top: 0px;
            right: 0%;
            z-index: 1;

            .close {
                background-color: transparent;
                position: absolute;
                top: 24px;
                right: 24px;
                width: 24px;
                height: 24px;
                border: none;

            }

            .close:hover {
                cursor: pointer;
            }

            .txt {
                text-align: left;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.38;
                letter-spacing: normal;
                color: #000;
                margin-bottom: 32px;
            }
        }

        // .dropdown:hover .dropdown-content {
        //     display: block;
        // }

        .dropdown-content div {
            color: #ffffff;
            padding: 0px 16px;
            display: block;
        }

        .choose {
            text-align: left;
            font-size: 20px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 28px;
            letter-spacing: normal;
            color: #000018;
            padding-top: 88px !important;
            padding-left: 16px !important;
            margin-bottom: 32px;
        }

        .dropdown-content div:hover {
            color: red;
        }
    }
}
</style>