<template>
    <section id="navigate-bar" class="active">
        <nav class="navigation" v-if="isMobile == false">
            <div class="container">
                <div class="nav-wrapper">
                    <RouterLink class="redirect-home " :to="`/${$i18n.locale}`" category="home">
                        <img class=" logo"
                            src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/DGxMU-Horizontal@1x.png"
                            alt="doogroup-logo">
                    </RouterLink>
                    <RouterLink class="home-btn nav-btn main" :class="currentRoute.name === `home` ? 'active' : ``"
                        :to="`/${$i18n.locale}`" v-t="'navigation.home'" page="home">
                    </RouterLink>
                    <div class="dropdown-wrapper main"
                        :class="['groupIntroduction', 'milestone', 'license', 'globalOffice'].includes(currentRoute.name) ? 'active' : ``">
                        <a class="market-btn nav-btn dropdown" v-t="'navigation.about-us'"></a>
                        <div class="dropdown-content" category="about">
                            <RouterLink :to="`/${$i18n.locale}/groupIntroduction`" @click="hideMenu"
                                v-t="'navigation.group-introduction'"
                                :class="currentRoute.name === `groupIntroduction` ? 'active' : ``" page="groupIntroduction">
                            </RouterLink>
                            <RouterLink :to="`/${$i18n.locale}/milestone`" @click="hideMenu" v-t="'navigation.milestone'"
                                :class="currentRoute.name === `milestone` ? 'active' : ``" page="milestone">
                            </RouterLink>
                            <RouterLink :to="`/${$i18n.locale}/license`" @click="hideMenu"
                                v-t="'navigation.regulatory-licenses'"
                                :class="currentRoute.name === `license` ? 'active' : ``" page="license"></RouterLink>
                            <RouterLink :to="`/${$i18n.locale}/globalOffice`" @click="hideMenu"
                                v-t="'navigation.global-operational-centers'"
                                :class="currentRoute.name === `globalOffice` ? 'active' : ``" page=" globalOffice">
                            </RouterLink>
                            <a href="https://career.doo.com/career/" v-t="'navigation.join-us'"></a>
                        </div>
                    </div>
                    <div class="dropdown-wrapper main"
                        :class="['businessoverview', 'brokerage', 'wealthmanagement', 'paymentexchange', 'fintech', 'financialeducation', 'healthcare'].includes(currentRoute.name) ? 'active' : ``">
                        <a class="market-btn nav-btn dropdown" v-t="'navigation.business-and-brand'"></a>
                        <div class="dropdown-content" category="businessAndBrand">
                            <RouterLink :to="`/${$i18n.locale}/businessoverview`" @click="hideMenu"
                                v-t="'navigation.business-overview'"
                                :class="currentRoute.name === `businessoverview` ? 'active' : ``" page="businessoverview">
                            </RouterLink>
                            <RouterLink :to="`/${$i18n.locale}/brokerage`" @click="hideMenu" v-t="'navigation.brokerage'"
                                :class="currentRoute.name === `brokerage` ? 'active' : ``" page="brokerage">
                            </RouterLink>
                            <RouterLink :to="`/${$i18n.locale}/wealthmanagement`" @click="hideMenu"
                                v-t="'navigation.wealth-management'"
                                :class="currentRoute.name === `wealthmanagement` ? 'active' : ``" page="wealthmanagement">
                            </RouterLink>
                            <RouterLink :to="`/${$i18n.locale}/property`" @click="hideMenu" v-t="'navigation.property'"
                                :class="currentRoute.name === `property` ? 'active' : ``" page="property">
                            </RouterLink>
                            <RouterLink :to="`/${$i18n.locale}/paymentexchange`" @click="hideMenu"
                                v-t="'navigation.payment-exchange'"
                                :class="currentRoute.name === `paymentexchange` ? 'active' : ``" page="paymentexchange">
                            </RouterLink>
                            <RouterLink :to="`/${$i18n.locale}/fintech`" @click="hideMenu" v-t="'navigation.finTech'"
                                :class="currentRoute.name === `fintech` ? 'active' : ``" page="fintech">
                            </RouterLink>
                            <RouterLink :to="`/${$i18n.locale}/financialeducation`" @click="hideMenu"
                                v-t="'navigation.financial-education'"
                                :class="currentRoute.name === `financialeducation` ? 'active' : ``"
                                page="financialeducation">
                            </RouterLink>
                            <RouterLink :to="`/${$i18n.locale}/healthcare`" @click="hideMenu" v-t="'navigation.healthcare'"
                                :class="currentRoute.name === `healthcare` ? 'active' : ``" page="healthcare">
                            </RouterLink>
                            <RouterLink :to="`/${$i18n.locale}/consult`" @click="hideMenu" v-t="'navigation.consulting'"
                                :class="currentRoute.name === `consult` ? 'active' : ``" page="consult">
                            </RouterLink>
                            <RouterLink :to="`/${$i18n.locale}/cloud`" @click="hideMenu" v-t="'navigation.cloud'"
                                :class="currentRoute.name === `cloud` ? 'active' : ``" page="healthcloudcloudcare">
                            </RouterLink>
                            <RouterLink :to="`/${$i18n.locale}/digital-marketing`" @click="hideMenu"
                                v-t="'navigation.digital-marketing'"
                                :class="currentRoute.name === `digital-marketing` ? 'active' : ``" page="digital-marketing">
                            </RouterLink>
                        </div>
                    </div>
                    <!--button class="tools-btn nav-btn dropdown " v-t="'navigation.business'"></button>
                    <button class="partnership-btn nav-btn dropdown" v-t="'navigation.partnership'"></button>
                    
                    <button class="trading-btn nav-btn " v-t="'navigation.esg'"></button-->
                    <div class="dropdown-wrapper main">
                        <a class="about-us-btn nav-btn dropdown" v-t="'navigation.partnership'">
                        </a>
                        <div class="dropdown-content">
                            <a :href="`${locationOrigin}/${$i18n.locale === 'sc' ? 'zh' : $i18n.locale}/partnership/manutd`"
                                v-t="'footer.Mu'"></a>
                            <a v-if="$i18n.locale === 'en'" :href="`${locationOrigin}/en/partnership/aufc`"
                                v-t="'footer.Au'"></a>

                        </div>
                    </div>
                    <div class="dropdown-wrapper main">
                        <a class="about-us-btn nav-btn dropdown" v-t="'navigation.news'">
                        </a>
                        <div class="dropdown-content">
                            <a href="https://blog.doo.com/" v-t="'footer.media'"></a>
                        </div>
                    </div>
                    <RouterLink class="home-btn nav-btn main" :to="`/${$i18n.locale}/contactus`" page="contactus"
                        :class="currentRoute.name === `contactus` ? 'active' : ``" v-t="'navigation.contact-us'">
                    </RouterLink>
                </div>
                <!--div class="search">
                </div-->

            </div>
        </nav>
        <nav class="navigation-mobile" v-else>
            <div class="container">
                <RouterLink class="redirect-home" :to="`/${$i18n.locale}`">
                    <img class="logo"
                        src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/DGxMU-Horizontal@1x_new.png"
                        alt="doogroup-logo">
                </RouterLink>
                <button @click="toggleMenu(true)" class="burger-btn">
                    <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/Menu.svg" alt="menu"
                        class="burger-menu">
                </button>

            </div>
        </nav>
        <div class="mobile-drop-menu" v-if="openMenuMobile">
            <button class="close" @click="toggleMenu(false)">
                <img class="close-icon" src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/Close.svg"
                    alt="close">
            </button>
            <MobileTable @close-nav="closeNavigation"></MobileTable>
        </div>
    </section>
</template>

<script>
import MobileTable from './LanguageModels/MobileTable'

export default {
    setup() {
        return {
            locationOrigin: window.location.origin
        };
    },
    components: {
        MobileTable
    },
    computed: {
        activePage() {

        }
    },
    data() {
        return {
            isMobile: false,
            openMenuMobile: false,
            currentRoute: this.$router.currentRoute
        }
    },
    methods: {
        checkMobile() {
            if (window.innerWidth <= 1100) {
                this.isMobile = true
            } else {
                this.isMobile = false
            }
        },
        toggleMenu(status) {
            let documentBody = document.querySelector("body");
            status ? documentBody.classList.add("noScroll") : documentBody.classList.remove("noScroll")
            this.openMenuMobile = status
        },
        closeNavigation(n) {
            let documentBody = document.querySelector("body");
            this.openMenuMobile = n;
            documentBody.classList.remove("noScroll")
        },
        headerMobile() {
            let scroll_position = 0;
            const navigateBar = document.querySelector("#navigate-bar");

            window.addEventListener('scroll', function (e) {
                if ((document.body.getBoundingClientRect()).top > scroll_position) {
                    navigateBar.classList.add("active");
                } else {
                    navigateBar.classList.remove("active");
                }
                scroll_position = (document.body.getBoundingClientRect()).top;
            });
        },
        scrollEffect() {
            window.addEventListener("scroll", () => {
                let currentScroll = window.scrollY;
                let navBar = document.querySelector("#navigate-bar");
                currentScroll < 50 ? navBar.style.transform = "translate(0px, 0px)" : navBar.style.transform = "";
            })
        },
        hideMenu() {
            const dropMenu = document.querySelectorAll('.dropdown-wrapper.main .dropdown-content');

            for (let i = 0; i < dropMenu.length; i++) {
                dropMenu[i].classList.add("hideMenu");
                setTimeout(() => {
                    dropMenu[i].classList.remove("hideMenu");
                }, 100);
            }
        },
    },
    mounted() {
        //console.log("current route", this.currentRoute)
        this.checkMobile();
        this.headerMobile();
        this.scrollEffect();
        window.addEventListener("resize", this.checkMobile);
    }
}

</script>

<style lang="less">
@import '../../less/mediaQuery.less';

body {
    &.noScroll {
        #navigate-bar {
            height: 100%;
        }
    }
}

body:not([data-scroll='20']) {
    #navigate-bar {
        transition: all 0.2s linear;

        @media only screen and (max-width:1100px) {
            top: 0;
            transform: translate(0px, -72px);

            &.active {

                transform: translate(0px, 0px);
            }
        }
    }
}

#navigate-bar {
    width: 100vw;
    background-size: cover;
    position: fixed;
    top: 32px;
    left: 0;
    z-index: 9;

    .navigation-mobile {
        background-color: #ffffff;
        box-shadow: 0 4px 16px 0 rgba(62, 58, 57, 0.1);
        height: 72px;

        .container {
            margin: auto;
            padding-top: 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .redirect-home {

                .logo {
                    width: 213px;
                    height: 40px;
                }
            }

            .search {
                display: flex;
                align-items: center;
            }

            .search::after {
                content: url('https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/Search.svg');
                width: 30px;
                height: 30px;
                display: block;
                margin-left: 30px;
            }

            .burger-btn {
                background-color: transparent;
                border: none;
                cursor: pointer;
            }
        }


    }

    .mobile-drop-menu {
        position: fixed;
        background-color: #ffff;
        width: 100%;
        height: 100%;
        z-index: 9999;
        top: 0;
        left: 0;

        .close {
            background-color: transparent;
            position: absolute;
            top: 24px;
            right: 16px;
            width: 24px;
            height: 24px;
            border: none;
        }
    }

    .dropdown-wrapper {
        position: relative;
        padding-top: 12px;
        padding-bottom: 12px;

        &:hover .dropdown-content {
            display: block;

        }

        &:hover {
            color: #e03323;

        }

        .dropdown-content {
            display: none;
            position: absolute;
            background-color: #ffffff;
            min-width: 160px;
            top: 36px;
            left: 2px;
            z-index: 10;
            min-width: 152px;
            text-align: left;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            color: #3e3a39;
            padding: 40px 24px 24px;
            box-shadow: 0 4px 16px 0 rgba(62, 58, 57, 0.1);

            &.hideMenu {
                display: none !important;
            }

            a {
                cursor: pointer;
                display: block;
                width: 100%;
                color: #3e3a39;
                display: block;
                margin-bottom: 16px;
                white-space: nowrap;

                &:hover,
                &:focus,
                &.active {
                    color: red;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .navigation {
        background-color: #ffffff;
        box-shadow: 0 4px 16px 0 rgba(62, 58, 57, 0.1);
        height: 80px;

        .container {
            margin: auto;
            padding-top: 14px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .nav-wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .redirect-home {
                margin-right: 56px;
                display: inline-block;

                .logo {
                    width: 256px;
                    height: 48px;
                }
            }

            .nav-btn {
                background-color: transparent;
                border: none;
                position: relative;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                padding: 0 24px;
                color: #3e3a39;
                cursor: pointer;
                position: relative;

                &:hover {
                    color: #e03323;

                    .dropdown-content {
                        display: block;
                    }
                }

                &.dropdown {
                    padding-right: 48px;
                }
            }

            .main {
                position: relative;

                &.active {
                    position: relative;

                    &:before {
                        content: '';
                        width: 0;
                        height: 0;
                        border-left: 10.5px solid transparent;
                        border-right: 10-.5px solid transparent;
                        border-top: 12px solid #f00;
                        position: absolute;
                        top: -28px;
                        left: 50%;
                        margin-left: -10px;
                    }

                    &.dropdown-wrapper {
                        &:before {
                            top: -16px;
                        }
                    }
                }
            }



            .dropdown::before {
                content: url(https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/Dropdown_dark.svg);
                position: absolute;
                right: 19px;
            }

            .search {
                display: flex;
                align-items: center;
            }

            .search::after {
                content: url('https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/Search.svg');
                width: 30px;
                height: 30px;
                display: block;
                margin-left: 30px;
            }
        }




    }
}
</style>