<template>
  <section id="latest-news">
    <div class="container">
      <p class="title" v-t="'latest-updates.latest-news'"></p>

      <div class="news">
        <a v-for="item in blogData" :href="item.href">
          <div class="wrapper">
            <img :src="item.imgHref" alt="" class="image" />
            <div class="content">
              <p class="title" v-html="item.title"></p>
              <p class="subtitle" v-html="item.description"></p>
              <p class="date">{{ item.date }}</p>
            </div>
          </div>
        </a>
      </div>
    </div>
    <carousel :items-to-show="1" v-if="blogData.length > 0" :wrap-around="true">
      <slide v-for="(item, index) in blogData" :key="index">
        <div class="wrapper">
          <a :href="item.href" class="carousel">
            <img :src="item.imgHref" alt="" class="image" />
          </a>
          <div class="content">
            <p class="title"  v-html="item.title"></p>
            <div class="subtitle-container">
              <p class="subtitle" v-html="item.description"></p>
              <p class="date">{{ item.date }}</p>
            </div>

          </div>
        </div>
      </slide>
      <template #addons>
        <navigation />
        <pagination />
      </template>
    </carousel>
    <div class="contact-us-container">
      <a class="btn-contact" v-t="'latest-updates.learn-more'" href="https://blog.doo.com/"></a>
    </div>
  </section>
</template>
<script setup>
import axios from "axios";
import { onMounted, ref, watch } from "vue";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import { useI18n } from 'vue-i18n';

const { locale } = useI18n();
const blogData = ref([]);

const removeHtmlTag = (string) => {
  return string.replaceAll(/(<([^>]+)>)/gi, "");
};

const getImage = async (id) => {
  try {
    const link = `https://blog.doo.com/wp-json/wp/v2/media/${id}`;
    const res = await axios(link);

    // if (res) return "https://blog.doo.com" + res.data.link;
    if (res) return res.data.link;
  } catch (error) {
    console.log("🚀 ~ file: LatestNews.vue:37 ~ getImage ~ error:", error);
  }
};

const getBlogData = async () => {
  const currentLocale = locale.value == "en" ? "en" : "sc";

  try {
    const data = await axios({
      method: "get",
      url: `https://blog.doo.com/${currentLocale}/wp-json/wp/v2/posts`,
      params: { per_page: 3 },
    });

    const imageList = [];
    if (data?.data) {
      blogData.value = data.data.map((item) => {
        imageList.push(getImage(item.featured_media));
        return {
          title: removeHtmlTag(item.title.rendered),
          description: removeHtmlTag(item.excerpt.rendered),
          date: item.date.split("T")[0],
          href: 'https://blog.doo.com' + `${item.link}`,
        };
      });
      const imageResult = await Promise.all(imageList);
      console.log( blogData.value);
      if (imageResult.length) {
        blogData.value = blogData.value.map((item, index) => ({
          ...item,
          imgHref: imageResult[index],
        }));
      }
    }
  } catch (err) {
    console.log("🚀 ~ file: LatestNews.vue:63 ~ getBlogData ~ err:", err);
  }
};

watch(locale, () => {
  getBlogData();
});

onMounted(() => {
  getBlogData();
});

</script>

<style lang="less">
@import '../../less/mediaQuery.less';
a {
  text-decoration: none !important;
}

#latest-news {
  padding-bottom:160px;

  @media @mobile-screen {
    margin: 0 16px;
    padding-bottom:80px;
  }

  @media only screen and (max-width: 500px) {
    margin: 0 16px;
  }

  .container {
    max-width: 1330px;

    .title {

      font-size: 48px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #3e3a39;
      margin-bottom: 80px;

      @media @mobile-screen {
        font-size: 28px;
        padding-top: 80px;
        margin-bottom: 40px;
      }

      @media only screen and (max-width: 500px) {
        font-size: 28px;
      }
    }
  }

  .news {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    column-gap: 25px;
    height: 100%;
    margin-bottom: 40px;

    @media only screen and (max-width:1190px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      a {
          &:last-child {
            margin-top:25px;
          }
        }
    }

    @media @mobile-screen {
      display: none;
    }

    .wrapper {
      display: flex;
      background-color: #f8f8f8;
      flex-direction: column;
      height: 100%;
      text-align: left;
      border-radius: 8px;
      overflow: hidden;

      .image {
        object-fit: contain;
        width: 100%;
        max-height: 427px;
      }

      .content {
        display: flex;
        flex-direction: column;
        padding: 32px;
        text-align: left;
        height: 290px;
        position: relative;
        font-family: "segoeui" !important;

        .title {
          font-size: 24px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.42;
          text-align: left;
          letter-spacing: normal;
          color: #3e3a39;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          margin-bottom:16px;
        }

        .subtitle {

          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: #3e3a39;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
        }

        .date {
          font-family: SegoeUI;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: #9e9e9f;
          position: absolute;
          bottom: 32px;
          left: 32px;
        }
      }
    }
  }

  .carousel {
    display: none;
    width: 100%;
    max-height: 420px;

    @media @mobile-screen {
      max-height: inherit;
      display: block;
    }

    @media @mobile-screen {
      .carousel__track {
        margin-bottom: 32px;
      }
    }

    .carousel__slide {
      @media @mobile-screen {
        align-items: baseline;
      }
    }

    .wrapper {
      display: flex;
      background-color: #f8f8f8;
      flex-direction: column;
      height: 100%;
      text-align: left;
      border-radius: 8px;
      overflow: hidden;

      .image {
        object-fit: contain;
        width: 100%;
        max-height: 427px;

        @media @mobile-screen {
          object-fit: cover;
        }
      }

      .content {
        display: flex;
        flex-direction: column;
        padding: 24px;
        font-family: "segoeui" !important;

        @media @mobile-screen {
          min-height: 222px;
        }

        .title {
          margin: 0;
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.4;
          letter-spacing: normal;
          color: #3e3a39;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        .subtitle {
          margin: 0;
          margin-top: 8px;
          
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: #3e3a39;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        .date {
          font-family: SegoeUI;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: #9e9e9f;
          padding-top: 16px;
        }
      }
    }
  }

  .contact-us-container {
    .btn-contact {

      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
      white-space: nowrap;
      border: none;
      width: 150px;
      height: 48px;
      margin: 40px 72px 160px 73px;
      padding: 12px 16px;
      background-color: #e03323;
      display: block;
      margin: 0 auto;
    }
  }

  .carousel__pagination-button::after {
    width: 6px;
    height: 6px;
  }

  .carousel__pagination-button--active::after {
    background-color: #e03323;
  }

  .carousel__prev,
  .carousel__next {
    display: none;
  }

  .carousel__pagination {
    margin: 0 !important;
    margin-top:32px !important;
    margin-bottom: 32px !important;
    padding: 0 !important;
  }
}</style>
