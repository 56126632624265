<template>
    <div class="content">
        <div class="bg bg1">
            <div class="logo-bg">
                <img :src="`${ImageUrl}HIGHLIGHTS_2023_1_mobile.png`" class="mobile"/>
                <img :src="`${ImageUrl}HIGHLIGHTS_DG.png`" class="logo"/>
            </div>
            <p>{{ t("aufc.DooGroup-2023-1") }}</p>
            <p>{{ t("aufc.DooGroup-2023-2") }}</p>
            <p>{{ t("aufc.DooGroup-2023-3") }}</p>
            <p>{{ t("aufc.DooGroup-2023-4") }}</p>
            <p>{{ t("aufc.DooGroup-2023-5") }}</p>
            <p>{{ t("aufc.DooGroup-2023-6") }}</p>
        </div>
        <div class="bg bg2">
            <div class="logo-bg">
                <img :src="`${ImageUrl}HIGHLIGHTS_2023_2_mobile.png`" class="mobile"/>
                <img :src="`${ImageUrl}HIGHLIGHTS_AU.png`" class="logo"/>
            </div>
            <div>
                <p>{{ t("aufc.AdelaideUnited-2023-1") }}</p>
                <p>{{ t("aufc.AdelaideUnited-2023-2") }}</p>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const ImageUrl = "https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/aufc/"
</script>

<style lang="less" scoped>
    .content {
        .bg1 {
            background: url("https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/aufc/HIGHLIGHTS_2023_1.png") no-repeat;
        }
        .bg2 {
            background: url("https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/aufc/HIGHLIGHTS_2023_2.png") no-repeat;
        }
    }
</style>